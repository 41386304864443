import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Link } from 'gatsby'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const ArticlesWrapper = styled.div`
  padding: 20px 0;
  float: left;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 50px;

  ${mobile(css`
    padding: 20px 0;
  `)}
`

export const FeaturedArticlesWrapper = styled.div`
  border-top: 2px solid #f2f1f1;
  border-right: 2px solid #f2f1f1;
  border-left: 2px solid #f2f1f1;
  margin: 30px 0;
  display: inline-block;

  .featured {
    border-bottom: 2px solid #f2f1f1;
    padding: 20px;
    width: 330px;
  }

  .section-title {
    text-transform: uppercase;
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 23px;
    font-weight: 700;
    margin-bottom: 40px;
    color: #01c08e;
    position: relative;
    padding-top: 11px;
    margin: 20px;
  }

  .section-title p {
    font-weight: 400;
    font-size: 22px;
    color: #333;
    margin: 0px;

    i {
      color: #54e0c2;
      margin-left: 90px;
      font-size: 15px;
    }
  }

  .section-title:after {
    content: '';
    position: absolute;
    width: 150px;
    height: 67px;
    background: #c4fff2;
    left: 80px;
    top: 0;
    z-index: -1;
  }

  ${mobile(css`
    padding: 20px 0;
  `)}
`

export const Wrapper = styled.div``

export const Image = styled.div`
  display: block;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 42px;
  margin-top: 10px;
  width: 100%;

  a {
    display: block;
    background-color: transparent;
    height: 240px;
    position: relative;
    padding: 0 12px 12px 0;

    &:after {
      content: '';
      position: absolute;
      top: 12px;
      right: 0;
      bottom: 0;
      left: 16px;
      background: #fdfce4;
      z-index: -1;
      border-radius: 17px !important;
      transition: all 0.3s linear;
    }

    &:hover:after {
      background: #c4fff2;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px 5px 80px 5px;
    overflow: hidden;
  }

  ${mobile(css`
    min-width: auto;
    width: 100%;
    margin: 0 0 20px 0;
    height: 200px;
  `)}
`

export const Content = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }

  h3:hover {
    color: ${colors.primary};
  }

  h3 {
    font-size: ${rem(22)};
    font-weight: ${fontWeights.regular};
    letter-spacing: -2px;
    margin: -8px 0 0 0;
    color: #333;
    line-height: ${rem(30)};
    margin-bottom: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .post-no {
    font-size: 20px;
    line-height: 30px;
    display: inline-block;
    border-bottom: 3px solid #313131;
    font-weight: 600;
    color: #313131;
    margin-bottom: 10px;
  }
`

export const Meta = styled.div`
  margin-bottom: 24px;

  span {
    display: inline-block;
    border-right: 1px ${colors.grey} solid;
    padding-right: 10px;
    color: ${colors.grey};
    font-style: italic;
    font-weight: ${fontWeights.medium};
    font-size: ${rem(14)};
    letter-spacing: -1px;

    &:last-of-type {
      padding-right: 0;
      border-right: none;
    }
  }
`

export const Excerpt = styled.div`
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  margin-bottom: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  min-height: 40px;

  ${mobile(css`
    line-height: ${rem(20)};
  `)}
`

export const ReadMore = styled(Link)`
  color: #54e0c2 !important;
  font-weight: 600;
  font-size: ${rem(16)};
  text-transform: uppercase;

  span {
    margin-right: 20px;
  }
`

export const LoadMore = styled.div`
  display: block;
  text-align: center;
  width: 215%;
  margin: 20px 0;

  button {
    background-color: ${colors.primary};
    border: none;
    border-radius: 2px;
    color: ${colors.contrast};
    outline: none;
    padding: 16px 36px;
    text-transform: uppercase;
    font-size: ${rem(12)};
    font-weight: ${fontWeights.medium};
    cursor: pointer;
  }
`

export const NoArticles = styled.div`
  font-size: 20px;
`

export const TrendingArticlesWrapper = styled.div`
  margin: 50px 0 40px;
  padding: 30px 0 40px;
  overflow: hidden;

  .wrapper {
    width: 100%;
    max-width: 1300px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .hpp-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .hpp-item {
    height: 200px;
    width: 20%;
    min-width: 250px;
    background: #fff;
  }

  .hppi-image {
    height: 100%;
    background-size: cover;
    background-position: 50%;
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column-reverse;
    padding: 10px 15px 20px;
    position: relative;
    z-index: 0;
    border: 1px solid #343434;
  }

  .hppi-image:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .hpp-item h3 {
    text-align: center;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    z-index: 2;
  }

  ${mobile(css`
    margin: 5px 0 15px;
    padding: 30px 0 10px;

    .wrapper {
      padding: 0 12px;
    }

    .hpp-items {
      overflow-x: scroll;
      overflow-y: hidden;
    }

    .hpp-item {
      height: 200px;
      width: 200px;
      min-width: 200px;
    }
  `)}
`

export const FeaturedArticleWrapper = styled.div`
  margin: 20px 0;

  .wrapper {
    width: 100%;
    max-width: 1300px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .hp-block {
    display: grid;
    grid-template-rows: 450px;
    grid-template-columns: 55% auto;
    grid-gap: 70px;
    color: #3c3c3c;
  }

  .hp-block > div {
    width: 100%;
  }

  .hp-image {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
  }

  .hp-right {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .hp-category {
    font-size: 13px;
    text-transform: uppercase;
    color: #aa053d;
    line-height: 1;
    font-weight: 500;
  }

  .hp-title {
    font-weight: 600;
    font-size: 34px;
    line-height: 1.4;
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .hp-descr {
    margin: 0 0 23px;
    font-size: 13px;
    color: #282828;
  }

  .hp-btn a {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    background: #ff552c;
    padding: 7px 30px;
  }

  ${mobile(css`
    .wrapper {
      padding: 0 12px;
    }

    .hp-block {
      grid-template-columns: 1fr;
      grid-template-rows: 300px auto;
      grid-gap: 15px;
    }

    .hp-category {
      font-size: 12px;
    }

    .hp-title {
      font-size: 20px;
      margin: 7px 0;
    }

    .home-post .hp-descr {
      margin: 0 0 15px;
    }
  `)}
`

export const PostArticlesWrapper = styled.div`
  margin: 20px 0;

  .wrapper {
    width: 100%;
    max-width: 1300px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .post-title {
    font-size: 30px;
    color: #3c3c3c;
    margin: 0 0 40px;
    font-weight: 600;
  }

  .post-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 45px;
  }

  .post-item {
    width: 100%;
    display: grid;
    grid-template-columns: 160px auto;
    grid-template-rows: 160px;
    grid-gap: 0 30px;
  }

  .post-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
    border-radius: 7px;
  }

  .post-info {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .post-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    margin: 5px 0;
    color: #3c3c3c;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .post-descr {
    color: #282828;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .post-more {
    text-align: center;
    margin: 50px auto;
  }

  .post-more button {
    color: #3c3c3c;
    padding: 7px 30px;
    border: 2px solid #3c3c3c;
    border-radius: 2px;
    font-weight: 600;
  }

  ${mobile(css`
    margin: 30px 0 0;

    .wrapper {
      padding: 0 12px;
    }

    .post-title {
      margin: 0 0 15px;
      font-size: 23px;
    }

    .post-items {
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }

    .post-item {
      grid-gap: 0 15px;
      grid-template-columns: 110px auto;
      grid-template-rows: 110px;
    }

    .post-image {
      border-radius: 5px;
    }

    .post-info {
      align-items: baseline;
      align-content: baseline;
      justify-content: left;
    }

    .post-name {
      font-size: 15px;
    }

    .post-descr {
      display: none;
    }

    .post-more {
      margin: 30px auto 50px;
      a {
        padding: 7px 40px;
      }
    }
  `)}
`

export const SearchArticlesWrapper = styled.div`
  padding-top: 20px;

  .sectionTitle {
    font-size: 15px;
    font-family: Helvetica Neue;
    color: #666;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .search-result-item {
    border-left: 3px solid #551a8b;
    padding-left: 10px;
    margin-bottom: 20px;
  }

  .search-result-item-title {
    font-size: 18px;
    color: #3300b5;
  }

  .search-result-description {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #525252;
    font-size: 14px;
    line-height: 20px;
  }

  ${mobile(css`
    margin: 10px 5px;
    padding: 10px;

    .search-result-item-title {
      font-size: 17px;
    }
  `)}
`

export const CategoriesContainer = styled.div`
  margin-top: 30px;
`

export const CategoryWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 0 20px;
  margin: 0 auto;

  .category-header {
    color: #ff552c;
    font-size: 30px;
    font-weight: 500;
    margin: 0 0 40px;
  }

  .category-list {
    margin: 0 auto 20px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 10px;

    img {
      height: 80px;
    }
  }

  a {
    text-align: center;
    font-size: 19px;
    line-height: 1.2;
  }

  ${mobile(css`
    padding: 0 12px;

    .category-header {
      font-size: 23px;
      margin: 0 0 15px;
    }

    .category-list {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: unset;
      grid-gap: 60px 10px;
    }
  `)}
`
